import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { onError } from 'apollo-link-error'

// BERA MIGRATION

// Should also update these to support different networks

const httpLink = new HttpLink({
  // uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-testnet/1.0.1/gn',
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-artio/latest/gn',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const retryLink = new RetryLink({
  delay: {
    initial: 1500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      console.log('retry error: ', error)
      // Check if the error is a network error
      const isNetworkError = error instanceof TypeError
      return isNetworkError
    },
  },
})

// const link = ApolloLink.from([retryLink, httpLink])
const link = ApolloLink.from([errorLink, retryLink, httpLink])

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/andreinnguyen/mumbai-block',
    // uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-testnet-blocks/1.0.0/gn',
    uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-artio-blocks/1.0.0/gn',
  }),
  cache: new InMemoryCache(),
})
