import React, { useEffect } from 'react'
import { HeaderProvider, RawHeader, useActiveLink } from '@kodiak-finance/ui-header'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import Toggle from '../Toggle'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

const BaseKodiakHeader = () => {
  const [isDark, toggleDarkMode] = useDarkModeManager()
  const [, setActiveLink] = useActiveLink()

  useEffect(() => {
    const page = window.location.pathname.split('/')[1]
    if (page === 'tokens' || page === 'token') {
      setActiveLink('nav-link-tokens')
    }
    if (page === 'pairs' || page === 'pair') {
      setActiveLink('nav-link-pairs')
    }
    if (page === 'account' || page === 'accounts') {
      setActiveLink('nav-link-accounts')
    }
  })
  return (
    <RawHeader>
      <Toggle isActive={isDark} toggle={toggleDarkMode} />
    </RawHeader>
  )
}

const KodiakHeader = () => {
  const [isDark] = useDarkModeManager()

  return (
    <HeaderProvider
      component={RouterLink}
      isDark={isDark}
      schema={[
        {
          to: '/tokens',
          id: 'nav-link-tokens',
          text: 'Tokens',
        },
        {
          to: '/pairs',
          id: 'nav-link-pairs',
          text: 'Pairs',
        },
        {
          to: '/accounts',
          id: 'nav-link-accounts',
          text: 'Accounts',
        },
        {
          text: 'About Kodiak',
          links: [
            {
              to: 'https://kodiak.finance',
              text: 'Kodiak Finance',
            },
            {
              to: process.env.REACT_APP_V3_ANALYTICS,
              text: 'V3 Analytics',
            },
            {
              to: '#',
              text: 'Docs',
            },
            {
              to: 'https://discord.gg/kodiak',
              text: 'Discord',
            },
            {
              to: 'https://x.com/KodiakFi',
              text: 'Twitter',
            },
          ],
        },
      ]}
    >
      <BaseKodiakHeader />
    </HeaderProvider>
  )
}

export default KodiakHeader
